import { AxiosResponse } from 'axios';

import { IDistrict, IProvince, IWard } from './types';

import axiosInstance from 'services/common/instance';

export const getProvincesByVietNameseService = async (): Promise<IProvince[]> => {
  const response = await axiosInstance.get<AxiosResponse<IProvince[]>>('locations/provinces-by-vietnamese');
  return response.data.data;
};

export const getDistrictsService = async (provinceCode: string): Promise<IDistrict[]> => {
  const response = await axiosInstance.get<AxiosResponse<IDistrict[]>>(`locations/districts/${provinceCode}`);
  return response.data.data;
};

export const getWardsService = async (districtCode: string): Promise<IWard[]> => {
  const response = await axiosInstance.get<AxiosResponse<IWard[]>>(`locations/wards/${districtCode}`);
  return response.data.data;
};
